import React from 'react';

const NavCareers = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.0897 15.223L27 16.7678L23 9.10756L26.1212 7.54698C26.3557 7.42973 26.6268 7.40926 26.8763 7.48998C27.1257 7.5707 27.3335 7.74614 27.4548 7.97854L30.5289 13.8657C30.5904 13.9834 30.6278 14.1122 30.639 14.2445C30.6501 14.3768 30.6348 14.5101 30.5939 14.6364C30.553 14.7628 30.4874 14.8797 30.4008 14.9804C30.3142 15.0811 30.2085 15.1636 30.0897 15.223V15.223Z"
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.99991 16.634L1.91021 15.0892C1.79143 15.0298 1.68567 14.9473 1.5991 14.8466C1.51253 14.7459 1.44688 14.629 1.406 14.5026C1.36511 14.3763 1.3498 14.243 1.36096 14.1107C1.37212 13.9784 1.40953 13.8496 1.471 13.7319L4.54511 7.84475C4.66646 7.61236 4.87422 7.43691 5.12366 7.35619C5.3731 7.27548 5.64426 7.29594 5.87875 7.41319L8.99991 8.97377L4.99991 16.634Z"
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27 16.7678L25 19.1075L20.4004 23.7072C20.2783 23.8293 20.1266 23.9177 19.9602 23.9638C19.7938 24.0099 19.6182 24.0121 19.4507 23.9702L12.2061 22.159C12.0702 22.1251 11.943 22.0629 11.8326 21.9766L5 16.6338"
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.9991 19.1079L19.4991 15.1079L17.8991 16.3079C17.2067 16.8272 16.3646 17.1079 15.4991 17.1079C14.6336 17.1079 13.7915 16.8272 13.0991 16.3079L12.4215 15.7997C12.3069 15.7138 12.2121 15.6042 12.1435 15.4784C12.0749 15.3526 12.0342 15.2135 12.024 15.0706C12.0138 14.9277 12.0345 14.7843 12.0846 14.65C12.1347 14.5158 12.2131 14.3939 12.3144 14.2926L17.2062 9.4008C17.299 9.30794 17.4093 9.23429 17.5306 9.18403C17.6519 9.13378 17.782 9.10791 17.9133 9.10791H22.9991"
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.07227 8.97363L15.4873 7.10344C15.7164 7.03664 15.9619 7.054 16.1794 7.1524L20.5005 9.10761"
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 26.6077L10.2326 25.6658C10.0797 25.6276 9.938 25.5538 9.81907 25.4504L7 23"
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NavCareers;
